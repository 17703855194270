@import url(https://use.fontawesome.com/releases/v5.7.1/css/all.css);
.pulse-button {
  /* background: black; */
  /* border-radius: 50%; */
  /* margin: 10px; */
  /* height: 20px; */
  /* width: 20px; */

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-animation: pulse 2s infinite;
          animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}


